<template>
	<div class="betRoom" v-if="flag">
		<header>
			<div class="g-header">
				<div class="left" @click="changego">
					<van-icon name="arrow-left" color="#aaa" size="18px" />
				</div>
				<div class="middle">
					<van-notice-bar :scrollable="false" color="#000">
						<van-swipe vertical class="notice-swipe" :autoplay="3000" :duration="1000"
							:show-indicators="false">
							<van-swipe-item><span class="fs-18">{{ name }}</span></van-swipe-item>
							<van-swipe-item><span class="fs-18">{{ $t(titles) }}</span></van-swipe-item>
							<!-- <van-swipe-item>内容 3</van-swipe-item> -->
						</van-swipe>
					</van-notice-bar>
				</div>
				<div class="right" style="color: #aaa;" @click="changehuancun"><!--showPopoverChange-->
					{{ $t("huancun") }}
					<!-- <van-icon name="plus" class="icon-arrow-bottom" :class="showPopover ? 'angleRote' : ''" color="#aaa"
						size="18px" /> -->
				</div>
			</div>
			<div class="blockHeight"></div>
		</header>
		<div class="g-content">
			<div class="bet-head f-a-c">
				<div class="left">
					<p class="cut-off">
						{{ $t("juli") }}<span>{{ issue.issue }}</span>
					</p>
					<template v-if="game_status == 1 && !whether_close">
						<van-count-down :time="time">
							<template #default="timeData">
								<span class="block">{{ timeData.minutes }}</span>
								<span class="colon">:</span>
								<span class="block">{{ timeData.seconds }}</span>
							</template>
						</van-count-down>
					</template>
					<template v-else-if="game_status == 1 && whether_close">
						<p class="stopBet">{{ $t("yifengpan") }}</p>
					</template>
					<template v-else>
						<p class="stopBet">{{ $t("yiguanb") }}</p>
					</template>
				</div>
				<div class="right">
					<van-loading type="spinner" v-if="user_quantityShow" />
					<div v-else @click="heart(), (user_quantityShow = true)">
						<div class="jine yczc">
							<p><span @click="aa">{{ $t("zongzichan") }}</span></p>
							<span>{{ user_quantity.money }}
								<font>{{ $t("qfh") }}</font>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="previousList">
					<div v-if="last_issue" class="pre-item Pc28" @click="(showIssue = !showIssue), showIssueChange()">
						<p class="issue">
							{{ $t("juli") }} <span class="c_fe5050">{{ last_issue.issue }}</span>
						</p>
						<div class="result">
							<span>{{ last_issue.site_1 }}</span>
							<span class="fs-1128">-</span>
							<span>{{ last_issue.site_2 }}</span>
							<span class="fs-1128">-</span>
							<span>{{ last_issue.site_3 }}</span>
							<span class="fs-1128">-</span>
							<span>{{ last_issue.code }}</span>
						</div>
						<p class="issueabc">
							{{ "(" + $t(last_issue.base[0]) + " " + $t(last_issue.base[1]) + ")" }}
						</p>
						<van-icon name="arrow-down" color="#aaa" size="18px"
							:class="showIssue ? 'angleRotestow' : 'icon-arrow-bottoms'" />
					</div>
				</div>

				<div class="bet-panel">
					<!-- <div class="panel-head">
						<ul class="category">
							<li class="category-item">
								{{$t('xzyouxishuoming')}}
							</li>
						</ul>
					</div> -->
					<div class="jpm_logo_middle">
						<p>{{ $t("wmjsb") }}</p>
						<p>{{ $t("wmjs") }}</p>
					</div>
					
					<div class="panel-body">

						<!-- <Chart :id="game_id" /> -->
						<div class="youxititle">{{$t('xzyouxishuoming')}}</div>
						<div class="oddsExplain">
							{{$t('yinglihezhi')}}
							<div class="tips" v-if="num_code">
								{{
                  oddsList.num_code[num_code]
                    ? oddsList.num_code[num_code]
                    : num_code
                }}
							</div>
							<div class="odds">
								<span>{{$t('shouyijieshao')}}</span>
							</div>
						</div>



						<div class="xiangmurongqi" v-if="oddsList.rule_base">
							<div class="item" :class="activePay.indexOf(index) >= 0  ? 'active' : ''"
								@click="addWan(item.class, item.class_name,index)"
								v-for="(item, index) in oddsList.rule_base" :key="index">
								<div class="square">
									<div class="text">{{ $t(item.class_name) }}</div>
									<div class="oddsPay">{{ item.odds }}</div>
								</div>
							</div>
						</div>

					</div>
					<div class="panel-foot">
						<div class="leftCount">
							<!-- <div class="icon-cart">
								<img src="@/assets/image/payu.png" alt="" />
							</div> -->
							<div class="bet-status" @click="showPayInput = !showPayInput">
								<img src="@/assets/image/jiaoyitb.png" alt="" />
								{{$t('zanweigouru')}}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="footer-chat" style="display: none;">
				<div class="input-box" @click="ChatTps">
					<input type="text" disabled="disabled" name="" id="" />
				</div>
				<div class="btn-bet" @click="changeShowPay()">{{ $t("gouru") }}</div>
			</div>
		</div>
		<!-- 右上角遮罩层 -->
		<van-overlay v-model:show="showPopover" @click="showPopover = false" style="z-index: 2005">
			<div class="head-menu">
				<ul>
					<li class="boderBottom" @click="changehuancun">
						<img src="@/assets/image/huancun.png" alt="" />
						<span>{{$t('qingchuhuancun')}}</span>
					</li>
				</ul>
			</div>
		</van-overlay>
		<!-- 前几期遮罩层 -->
		<van-overlay v-model:show="showIssue" @click="showIssue = false">
			<div class="issuecontant">
				<div class="previousList">
					<template v-if="issueList">
						<div class="pre-item Pc28 xialashuju" @click="showIssue = !showIssue"
							v-for="(item, index) in issueList" :key="index">
							<p class="issue">
								{{$t('juli')}} <span>{{ item.issue }}</span>
							</p>
							<div class="result">
								<span>{{ item.site_1 }}</span>
								<span class="fs-1128">-</span>
								<span>{{ item.site_2 }}</span>
								<span class="fs-1128">-</span>
								<span>{{ item.site_3 }}</span>
								<span class="fs-1128">-</span>
								<span>{{ item.code }}</span>
							</div>
							<p class="issueabc">
								{{ "(" + $t(item.base[0]) + " " + $t(item.base[1]) + ")" }}
							</p>
						</div>
					</template>
				</div>
			</div>
		</van-overlay>
		<!-- 购入键盘 -->

		<!-- 购买列表里的遮罩层，最后输入的数量 -->
		<div style="z-index: 2004;!important">
			<van-popup v-model:show="showPayInput" position="bottom" :style="{ height: '26%', width: '100%' }">
				<div class="bet-sub-panel">
					<ul>
						<li :class="betselect == 0 ? 'betactive' : ''" @click="(betselect = 0), InputValeue(0)">
							500 <span>{{$t('qfh')}}</span><!-- <img src="@/assets/image/bet5.png" alt="" /> -->
						</li>
						<li :class="betselect == 1 ? 'betactive' : ''" @click="(betselect = 1), InputValeue(1)">
							1000 <span>{{$t('qfh')}}</span><!-- <img src="@/assets/image/bet10.png" alt="" /> -->
						</li>
						<li :class="betselect == 2 ? 'betactive' : ''" @click="(betselect = 2), InputValeue(2)">
							5000 <span>{{$t('qfh')}}</span><!-- <img src="@/assets/image/bet50.png" alt="" /> -->
						</li>
						<li :class="betselect == 3 ? 'betactive' : ''" @click="(betselect = 3), InputValeue(3)">
							20000 <span>{{$t('qfh')}}</span><!-- <img src="@/assets/image/bet300.png" alt="" /> -->
						</li>
						<li :class="betselect == 4 ? 'betactive' : ''" @click="(betselect = 4), InputValeue(4)">
							50000 <span>{{$t('qfh')}}</span><!-- <img src="@/assets/image/bet500.png" alt="" /> -->
						</li>
						<li :class="betselect == 5 ? 'betactive' : ''" @click="(betselect = 5), InputValeue(5)">
							ALL<!-- <img src="@/assets/image/betall.png" alt="" /> -->
						</li>
					</ul>
					<div class="bottom-form">
						<!-- <span class="c_a0a0a0">{{$t('gourushuliang')}}：</span> -->
						<input type="number" v-model="sendValue" class="" :placeholder="$t('gourushuliang')" />
						<div class="ripple bet-btn" @click="playOrder">{{$t('gouru')}}</div>
					</div>
				</div>
			</van-popup>
		</div>

		<!--shuzi-->
		<!-- <div style="z-index: 2004;!important">
			<van-popup v-model:show="showPayInputa" position="bottom" :style="{ height: '22%', width: '100%' }">
				<div class="bet-sub-panel">
					<ul>
						<li :class="betselect == 0 ? 'betactive' : ''" @click="(betselect = 0), InputValeue(0)">
							<img src="@/assets/image/bet5.png" alt="" />
						</li>
						<li :class="betselect == 1 ? 'betactive' : ''" @click="(betselect = 1), InputValeue(1)">
							<img src="@/assets/image/bet10.png" alt="" />
						</li>
						<li :class="betselect == 2 ? 'betactive' : ''" @click="(betselect = 2), InputValeue(2)">
							<img src="@/assets/image/bet50.png" alt="" />
						</li>
						<li :class="betselect == 3 ? 'betactive' : ''" @click="(betselect = 3), InputValeue(3)">
							<img src="@/assets/image/bet300.png" alt="" />
						</li>
						<li :class="betselect == 4 ? 'betactive' : ''" @click="(betselect = 4), InputValeue(4)">
							<img src="@/assets/image/bet500.png" alt="" />
						</li>
						<li :class="betselect == 5 ? 'betactive' : ''" @click="(betselect = 5), InputValeue(5)">
							<img src="@/assets/image/betall.png" alt="" />
						</li>
					</ul>
					<div class="bottom-form">
						<span class="c_a0a0a0">{{$t('gourushuliang')}}：</span>
						<input type="number" v-model="sendValue" class="" :placeholder="$t('gourushuliang')" />
						<div class="ripple bet-btn" @click="playOrder">{{$t('gouru')}}</div>
					</div>
				</div>
			</van-popup>
		</div> -->
		<!-- 跟购-->
		<van-overlay v-model:show="showFollowPay" @click="showFollowPay = true" style="z-index: 2005">
			<div class="bet fs-12">
				<div class="bet-title fs-14">{{$t('quedinggengou')}}?</div>
				<div class="bet-content">
					<div>
						<span class="c_a0a0a0">{{$t('wanjia')}}：</span>
						<span>{{ FollPayItem.nickname }}</span>
					</div>
					<div>
						<span class="c_a0a0a0">{{$t('lunshu')}}：</span>
						<span>{{ FollPayItem.issue }}</span>
					</div>
					<div class="c_a0a0a0">
						<span>{{$t('leibe')}}</span>
						<span>{{$t('jine')}}</span>
					</div>
					<ul>
						<li>
							<span>{{ $t(item.FollPayItem.play_method) }}</span>
							<span>{{ FollPayItem.quantity }}</span>
						</li>
					</ul>
				</div>
				<div class="bet-footer">
					<van-button type="primary" class="btn-confirm" size="small" round
						@click="FollmePay(FollPayItem)">{{$t('queren')}}</van-button>
					<van-button type="primary" class="btn-canle" size="small" round>{{$t('quxiao')}}</van-button>
				</div>
			</div>
		</van-overlay>
		<alert-pop :alertType="alertType" :Tips="TipsTxt" v-if="PopAlert" @showAletfalse="showAletfalse"></alert-pop>
		<!-- 中奖消息 -->
		<!-- <div
      class="Xwarp"
      id="xwarpdonghua"
      v-if="Myprize.length != 0 && donghuaShow"
      :style="transformXwarp"
      ref="boxdonghua"
    >
      <div>
        {{$t('gongxi')}}{{ Myprize[0].nickname }}{{$t('zai')}}{{ Myprize[0].game_name }}{{$t('yingli')}}{{
          Myprize[0].profit
        }}U
      </div>
    </div> -->
	</div>
</template>
<script>
	import Swiper from "swiper";
	import 'swiper/swiper-bundle.css';
	import homeApi from "@/api/home";
	import AlertPop from "@/components/alert/AlertPop.vue";
	// import Chart from "@/components/chart/Chart.vue";
	export default {
		name: "BetRoom",
		components: {
			AlertPop,
			// Chart
		},
		data() {
			return {
				time: "",
				flag: false,
				showPopover: false,
				showIssue: false,
				TopPX: 0,
				transformSwiper: "transform:translate3d(0px, -" + 0 + "px, 0px)",
				showPay: false,
				activePay: [],
				activePaya: null,
				showPayInput: false,
				showPayInputa: false,
				swiperKeySlide: 0,
				PaySwiperexample: null,
				betselect: null,
				id: null,
				game_id: null,
				oddsList: null,
				num_code: null,
				sendValue: "",
				last_issue: "",
				issueList: null,
				issue: "",
				name: "",
				titles: "",
				TouchStart: false,
				showFollowPay: false,
				message: [],
				game_status: 1,
				PopAlert: false,
				TipsTxt: "",
				alertType: 1,
				user_quantity: "",
				user_quantityShow: true,
				class: [],
				FollPayItem: "",
				whether_close: null,
				timeDataFun: null,
				daodibu: false,
				Myprize: [],
				NOprize: [],
				preizeIsPush: false,
				issuePreize: "",
				timedonghua: null,
				transformXwarp: "translate3d(0, 0px, 0px);",
				donghuaShow: true,
				startqishu: 0,
				houtaiqishu: 0,
			};
		},
		watch: {
			issuePreize(newv, old) {
				if (newv != old) {
					this.preizeIsPush = false;
					// transform: translate3d(0, 0px, 0px);
				}
			},
			// donghuaShow() {
			//   let that=this
			//   setInterval(() => {
			//     that.donghuaShow = false;
			//     console.log(that.donghuaShow)
			//   }, 5000);
			// },
		},
		computed: {
			money() {
				console.log(this.$store.state.money);
				return this.$store.state.money;
			},
			currentUserId() {
				console.log(this.$store.state.currentUserId);
				return this.$store.state.currentUserId;
			},

		},
		methods: {
			InputValeue(type) {
				if (type == 0) {
					this.sendValue = 500;
				} else if (type == 1) {
					this.sendValue = 1000;
				} else if (type == 2) {
					this.sendValue = 5000;
				} else if (type == 3) {
					this.sendValue = 20000;
				} else if (type == 4) {
					this.sendValue = 50000;
				} else {
					// 全下
					this.sendValue = this.user_quantity.money * 1 - 1;
				}
			},
			// 下注接口
			playOrder() {
				// if (this.sendValue * 1 > this.user_quantity.money * 1) {
				//   this.$toast("您的资产不足请存入");
				//   return;
				// }
				this.$toast.loading({
					message: this.$t('jiazaizhong'),
					forbidClick: true,
					duration: 0,
				});
				homeApi
					.playOrder(
						this.houtaiqishu,
						this.id,
						this.game_id,
						JSON.stringify(this.class),
						this.sendValue
					)
					.then((data) => {
						//this.$toast.clear();
						//this.showPay = false;
						this.showPayInput = false;
						this.num_code = null;
						//this.message.push(data.data);
						//this.daodibu = false;
						//this.$refs.swiperBox.scrollTop = 99999;
						this.$toast(this.$t('xiadanchenggong'));
						//  localStorage.setItem("money", (this.user_quantity.money*1)- ( this.sendValue*1));
						this.user_quantity.money = data.data.after_quantity;
					})
					.catch((err) => {
						this.$toast.clear();
						this.$toast.fail(this.$t(err.msg));
					});
			},
			changego() {
				this.$router.go(-1);
			},
			showFollowPayFun(item) {
				if (this.game_status != 1 || this.whether_close) {
					this.TipsTxt = this.$t('guanbitg');
					this.PopAlert = true;
					return;
				}
				if (item.issue == this.issue.issue) {
					this.FollPayItem = item;
					this.showFollowPay = true;
				} else {
					this.TipsTxt = this.$t('xiangoudl');
					this.PopAlert = true;
				}
			},
			showAletfalse() {
				this.PopAlert = false;
			},
			changeDetail() {
				this.$router.push({
					path: "/lotteryDetail",
					query: {
						game_id: this.game_id,
						name: this.name,
					},
				});
			},
			changeRecharge() {
				this.$router.push({
					path: "/recharge",
				});
			},
			changebetRecord() {
				this.$router.push({
					path: "/betRecord",
				});
			},
			changeLotteryExplain() {
				this.$router.push({
					path: "/lotteryExplain",
				});
			},
			changeTrendChart() {
				this.$router.push({
					path: "/trendChart",
					query: {
						game_id: this.game_id,
						name: this.name,
					},
				});
			},
			showPopoverChange() {
				if (!this.showIssue) {
					this.showPopover = !this.showPopover;
				} else {
					this.showIssue = false;
				}
			},
			showIssueChange() {
				homeApi
					.issueList(this.game_id)
					.then((data) => {
						console.log(data.data);
						let list = data.data.slice(1, 5);
						this.issueList = list;
					})
					.catch((err) => {
						this.$toast.fail(err.msg);
					});
			},
			changeShowPay() {
				/* if (this.game_status == 1 && !this.whether_close) {
				  this.showPay = true;
				} else {
				  this.TipsTxt = this.$t('guanbitg');
				  this.PopAlert = true;
				} */
				let that = this;

				setTimeout(() => {
					that.PaySwiperexample = new Swiper("#PaySwiper", {
						observer: true, //修改swiper自己或子元素时，自动初始化swiper
						observeParents: true, //修改swiper的父元素时，自动初始化swiper
						// 如果需要分页器
						pagination: ".swiper-pagination",
						// 如果需要前进后退按钮
						nextButton: ".swiper-button-next",
						prevButton: ".swiper-button-prev",
						onSlideChangeEnd: function(swiper) {
							swiper.activeIndex;
							that.swiperKeySlide = swiper.activeIndex;
							that.activePay = [];
							that.num_code = null;
							that.class = "";
							that.sendValue = "";
						},
					});
				}, 300);
			},
			changeShowPayInput(classname, itemName) {
				this.class = classname;
				this.betselect = null;
				this.num_code = itemName;
				this.showPayInput = true;
				this.sendValue = "";
				this.activePaya = null;
			},
			addWan(classname, itemName, index) {
				if (this.activePay.indexOf(index) >= 0) {
					this.activePay = this.activePay.filter(function(item) {
						return item !== index
					});
					this.class = this.class.filter(function(item) {
						return item !== classname
					});
				} else {
					this.activePay.push(index);
					this.class.push(classname);
				}

				this.betselect = null;
				this.num_code = itemName;
				this.sendValue = "";
				this.activePaya = null;
			},
			changeShowPayInputa(classname, itemName) {
				this.class = classname;
				this.betselect = null;
				this.num_code = itemName;
				this.showPayInputa = true;
				this.sendValue = "";
				this.activePay = null;
			},
			changeTabPay(index) {
				this.sendValue = "";
				this.activePay = null;
				this.class = "";
				this.PaySwiperexample = this.PaySwiperexample ?? new Swiper("#PaySwiper");
				this.PaySwiperexample.slideTo(index, 300, false);
			},
			heart() {
				homeApi
					.heart(this.id, this.game_id)
					.then((data) => {
						this.last_issue = data.data.last_issue;
						this.issue = data.data.issue;
						this.houtaiqishu = data.data.issue.issue;
						this.game_status = data.data.game_status;
						this.user_quantity = data.data.user_quantity;
						this.time = data.data.issue.openTime1 * 1000;
						this.whether_close = data.data.issue.whether_close;
						this.user_quantityShow = false;
						this.$toast.clear();
					})
					.catch((err) => {
						this.$toast.clear();
						this.$toast.fail(err.msg);
					});
			},
			difference(aArray, bArray) {
				const bArraySet = new Set(bArray);
				const resultArray = aArray.filter((item) => !bArraySet.has(item));
				return Array.from(resultArray);
			},
			getResultArray(key, aArray, bArray) {
				const aArraySimple = aArray.map((i) => i[key]); // 根据唯一标识对象数组转换为简单数组
				const bArraySimple = bArray.map((i) => i[key]);
				const allData = [...aArray, ...bArray];
				const resultArraySimple = Array.from(
					this.difference(aArraySimple, bArraySimple)
				);
				console.log(
					resultArraySimple.map((i) => allData.find((j) => j[key] === i))
				);
				return resultArraySimple.map((i) => allData.find((j) => j[key] === i));
			},

			getDifferenceSet(arr1, arr2) {
				return arr1
					.map(JSON.stringify)
					.concat(arr2.map(JSON.stringify))
					.filter((v, i, arr) => {
						return arr.indexOf(v) === arr.lastIndexOf(v);
					})
					.map(JSON.parse);
			},
			FollmePay(item) {
				console.log("跟购");
				homeApi
					.playOrder(
						this.houtaiqishu,
						this.id,
						this.game_id,
						item.play_type,
						item.quantity
					)
					.then((data) => {
						this.$toast.clear();
						this.message.push(data.data);
						this.$toast(this.$t('xiadanchenggong'));
						this.user_quantity.money = this.user_quantity.money =
							data.data.after_quantity;
						this.$refs.swiperBox.scrollTop = 99999;
						this.daodibu = false;
					})
					.catch((err) => {
						this.$toast.fail(err.msg);
					});
			},
			ChatTps() {
				this.$toast(this.$t('lttip'));
			},
			changehuancun() {
				location.reload();
			},
			qishu() {
				console.log(3333333)
				var timeZoneDiff = 8;

				var offsetTime = new Date().getTimezoneOffset();

				var timeStamp = new Date().getTime();
				var Dateobj = new Date(timeStamp + offsetTime * 60 * 1000 + timeZoneDiff * 60 * 60 * 1000);
				var Dateobj_last = new Date(timeStamp + offsetTime * 60 * 1000 + timeZoneDiff * 60 * 60 * 1000 - 5 * 60 *
					1000);
				var Dateobj_next = new Date(timeStamp + offsetTime * 60 * 1000 + timeZoneDiff * 60 * 60 * 1000 + 5 * 60 *
					1000);
				var seconds = Dateobj.getSeconds()
				var minutes = Dateobj.getMinutes()
				var hours = Dateobj.getHours()
				var dates = Dateobj.getDate()
				var month = Dateobj.getMonth() + 1
				var fullyear = Dateobj.getFullYear()

				//var seconds_last=Dateobj_last.getSeconds()
				var minutes_last = Dateobj_last.getMinutes()
				var hours_last = Dateobj_last.getHours()
				var dates_last = Dateobj_last.getDate()
				var month_last = Dateobj_last.getMonth() + 1
				var fullyear_last = Dateobj_last.getFullYear()

				//var seconds_next=Dateobj_next.getSeconds()
				var minutes_next = Dateobj_next.getMinutes()
				var hours_next = Dateobj_next.getHours()
				var dates_next = Dateobj_next.getDate()
				var month_next = Dateobj_next.getMonth() + 1
				var fullyear_next = Dateobj_next.getFullYear()




				if (month < 10) {
					month = '0' + month;
				}
				if (month_last < 10) {
					month_last = '0' + month_last;
				}
				if (month_next < 10) {
					month_next = '0' + month_next;
				}
				if (dates < 10) {
					dates = '0' + dates;
				}
				if (dates_last < 10) {
					dates_last = '0' + dates_last;
				}
				if (dates_next < 10) {
					dates_next = '0' + dates_next;
				}
				if (hours < 10) {
					hours = '0' + hours;
				}
				if (hours_last < 10) {
					hours_last = '0' + hours_last;
				}
				if (hours_next < 10) {
					hours_next = '0' + hours_next;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (minutes_last < 10) {
					minutes_last = '0' + minutes_last;
				}
				if (minutes_next < 10) {
					minutes_next = '0' + minutes_next;
				}
				var nowtime = fullyear + '' + month + '' + dates + '' + hours + '' + minutes
				var sur = parseInt(minutes) % 5
				var openTime = ''
				var openTime1 = 0
				var issue = 0
				var last_issue = 0
				if (sur == 0 && seconds < 30) {
					openTime = fullyear + '-' + month + '-' + dates + ' ' + hours + ':' + minutes + ':30';
					openTime1 = 30 - seconds;
					issue = parseInt(nowtime);
					last_issue = fullyear_last + '' + month_last + '' + dates_last + '' + hours_last + '' +
						minutes_last;
				} else if (sur == 0 && seconds > 30) {
					openTime = fullyear_next + '-' + month_next + '-' + dates_next + ' ' + hours_next + ':' +
						minutes_next + ':30';
					openTime1 = 5 * 60 - (seconds - 30);
					issue = fullyear_next + '' + month_next + '' + dates_next + '' + hours_next + '' + minutes_next;
					last_issue = nowtime;
				} else {
					var temp = 5 - sur;
					var Dateobj_temp = new Date(timeStamp + offsetTime * 60 * 1000 + timeZoneDiff * 60 * 60 * 1000 + temp *
						60 * 1000);
					//var seconds_temp=Dateobj_temp.getSeconds()
					var minutes_temp = Dateobj_temp.getMinutes()
					var hours_temp = Dateobj_temp.getHours()
					var dates_temp = Dateobj_temp.getDate()
					var month_temp = Dateobj_temp.getMonth() + 1
					var fullyear_temp = Dateobj_temp.getFullYear()
					if (month_temp < 10) {
						month_temp = '0' + month_temp;
					}
					if (dates_temp < 10) {
						dates_temp = '0' + dates_temp;
					}
					if (hours_temp < 10) {
						hours_temp = '0' + hours_temp;
					}
					if (minutes_temp < 10) {
						minutes_temp = '0' + minutes_temp;
					}
					openTime = fullyear_temp + '-' + month_temp + '-' + dates_temp + ' ' + hours_temp + ':' +
						minutes_temp + ':30';
					openTime1 = 300 - 30 - (sur - 1) * 60 - seconds;
					issue = fullyear_temp + '' + month_temp + '' + dates_temp + '' + hours_temp + '' + minutes_temp;

					var Dateobj_temp_last = new Date(timeStamp + offsetTime * 60 * 1000 + timeZoneDiff * 60 * 60 * 1000 -
						sur * 60 * 1000);
					//var seconds_temp_last=Dateobj_temp_last.getSeconds()
					var minutes_temp_last = Dateobj_temp_last.getMinutes()
					var hours_temp_last = Dateobj_temp_last.getHours()
					var dates_temp_last = Dateobj_temp_last.getDate()
					var month_temp_last = Dateobj_temp_last.getMonth() + 1
					var fullyear_temp_last = Dateobj_temp_last.getFullYear()
					if (month_temp_last < 10) {
						month_temp_last = '0' + month_temp_last;
					}
					if (dates_temp_last < 10) {
						dates_temp_last = '0' + dates_temp_last;
					}
					if (hours_temp_last < 10) {
						hours_temp_last = '0' + hours_temp_last;
					}
					if (minutes_temp_last < 10) {
						minutes_temp_last = '0' + minutes_temp_last;
					}
					last_issue = fullyear_temp_last + '' + month_temp_last + '' + dates_temp_last + '' + hours_temp_last +
						'' +
						minutes_temp_last;

				}

				var whether_close = false
				if (parseInt(issue) <= parseInt(nowtime)) {
					whether_close = true
				}
				var datajs = {};
				datajs.issue = parseInt(issue);
				datajs.last_issue = parseInt(last_issue);
				datajs.openTime = openTime;
				datajs.openTime1 = openTime1;
				datajs.whether_close = whether_close;

				this.time = openTime1 * 1000;
				this.issue = datajs;
				this.issuePreize = parseInt(issue);
				this.whether_close = whether_close;
				console.log(this.time)
				console.log(this.issue)
				console.log(this.issuePreize)
				console.log(this.whether_close)
				setTimeout(() => {
					this.qishu();
				}, 1000);
			},
		},

		mounted() {

		},
		onUpdated() {},
		unmounted() {
			clearInterval(this.timeDataFun);
			this.timeDataFun = null;
		},
		created() {
			let that = this;
			this.$toast.loading({
				message: this.$t('jiazaizhong'),
				forbidClick: true,
				duration: 0,
			});



			this.id = this.$route.query.id;
			this.game_id = this.$route.query.game_id;
			this.name = this.$route.query.name;
			this.titles = this.$route.query.title;




			homeApi
				.oddsList(this.id)
				.then((data) => {
					this.oddsList = data.data;
					this.$toast.clear();
					this.flag = true
				})
				.catch((err) => {
					this.$toast.clear();
					this.$toast.fail(err.msg);
				});
			this.heart();
			this.timeDataFun = setInterval(() => {

				this.qishu();

				// 对接接口就是轮询后有新数据就进行滚动
				//需要定时执行的代码
				homeApi
					.heart(this.id, this.game_id)
					.then((data) => {
						this.last_issue = data.data.last_issue;

						if (!this.preizeIsPush && data.data.hit_notice) {
							this.Myprize = [];
							this.Myprize.push(...data.data.hit_notice);

							console.log(this.NOprize, this.preizeIsPush, "中奖消息");
							this.preizeIsPush = true;
						}
						if (that.message.length != 0) {
							this.game_status = data.data.game_status;
							//this.time = data.data.issue.openTime1 * 1000;
							//this.issue = data.data.issue;
							this.houtaiqishu = data.data.issue.issue;
							//this.issuePreize = data.data.issue.issue;
							this.user_quantity = data.data.user_quantity;
							//this.whether_close = data.data.issue.whether_close;
							console.log(this.whether_close, "this.whether_close");

							if (data.data.message.length != 0 && data.data.message) {

								let newlist = that.getDifferenceSet(
									that.message,
									data.data.message
								);
								if (newlist.length != 0) {
									//  取相差有问题
									if (that.message.length > 300) {
										that.message.splice(1, 30)
									}
									that.message.push(...newlist);
									console.log(newlist);
									that.daodibu = false;
								}
							}
						} else {
							if (
								that.message.length == 0 &&
								data.data.message.length != 0 &&
								data.data.message
							) {
								that.message = data.data.message;
							}
						}

						this.$toast.clear();
					})
					.catch(() => {
						this.$toast.clear();
					});

			}, 2000);

		},
	};
</script>
<style lang="less" scoped>
	.betRoom {
		background: url("../assets/image/n_banner_bg.jpg");
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: 0 -20px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		height: 100%;
	}

	.van-notice-bar {
		background: none;
		overflow: hidden;
	}

	.bet-head {
		overflow: hidden;
		text-align: center;
		background: #f6f7f9;
		padding: 0.6rem 0.3rem;
		font-size: 0.3rem;
		z-index: 2003;
		width: calc(100% - 0.6rem);
		margin: 0 auto;
		background: #E31837;
		border-radius: 5px;

		.left {
			flex: 1;
			position: relative;
			color: #fff;
		}

		.left ::after {
			content: "";
			display: block;
			width: 0.02rem;
			height: 0.8rem;
			position: absolute;
			background-color: rgba(255, 255, 255, 0.1);
			right: 0;
			top: 50%;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
		}

		.right {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
			padding-left: 0.6rem;
			position: relative;

			.yczc {
				font-size: 0.32rem;
				text-align: left;

				span {
					font-size: 0.32rem;
				}

				span:nth-child(1) {
					color: #fff;
				}

				span:nth-child(2) {
					font-size: 0.44rem;
					font-family: 'nstb';
					color: #fff;
					padding-top: 1px;
					display: block;

					font {
						margin-left: 2px;
						font-size: .28rem;
						font-size: 300;
					}
				}
			}
		}
	}

	.cut-off {
		span {
			font-family: 'nstb';
		}
	}

	.colon {
		color: #fff;
		font-size: .28rem;
		height: 0.42rem;
	}

	.block {
		width: 0.52rem;
		height: 0.42rem;
		line-height: 0.42rem;
		background-color: #fff;
		border-radius: 2px;
		color: #E31837;
		padding: 0;
		margin: 0 3px;
		display: inline-block;
		border: none;
		font-size: .28rem;
		font-family: 'nstb';
	}

	.icon-gold {
		width: 0.3rem;
		height: 0.3rem;
		margin: 0 0.2rem;
	}

	.caijin {
		margin-top: 0.2rem;
	}

	.pre-item {
		background-color: #fff;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: .4rem .3rem;
		font-size: 0.32rem;
		position: relative;
		z-index: 2003;
	}

	.issue {
		white-space: nowrap;
	}

	.issueabc {
		white-space: nowrap;
		font-size: .28rem;
	}

	.result {
		min-width: 1.44rem;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin: 0 0.2rem;
		font-size: 0.32rem;
		font-family: 'nstb';
		color: #000;
	}

	.pre-item:after {
		content: " ";
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 1px;
		border-bottom: 1px solid #f6f7f9;
		color: #aaa;
		-webkit-transform-origin: 0 100%;
		transform-origin: 0 100%;
		-webkit-transform: scaleY(0.5);
		transform: scaleY(0.5);
	}

	.xialashuju {
		color: #aaa;
	}

	.scroll-thead {
		display: flex;
		padding: 0.2rem 0;
		text-align: center;
		background-color: #1a243f;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		span {
			flex: 1;
			font-size: 0.35rem;
		}
	}

	.scroller-box {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		width: 100%;
	}

	.msg-box {
		// min-height: calc(100% + 1px);
	}

	.msg-item {}

	.bet-msg {
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;

		text-align: center;
		background-color: #1a243f;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		min-height: 1rem;
		padding: 0.4rem 0;
	}

	.flex1 {
		flex: 1;
		word-break: break-all;
	}

	.footer-chat {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 0.3rem 0.2rem;
		background-color: #30385d;
		border-top: 1px solid #3d5179;
		padding-bottom: calc(0.1rem + constant(safe-area-inset-bottom));
	}

	.input-box {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		height: 1rem;
		margin-right: 0.2rem;
	}

	.input-box input {
		width: 100%;
		height: 100%;
		border: none;
		border-radius: 5px;
	}

	.input-box input:disabled {
		background: #1a243f;
		opacity: 1;
	}

	.btn-bet {
		background-color: #7d58c6;
		height: 1rem;
		line-height: 1rem;
		width: 1.6rem;
		text-align: center;
		border-radius: 5px;
	}

	.icon-arrow-bottom {
		transition: transform 0.3s;
	}

	.angleRote {
		transform: rotate(135deg);
		transition: transform 0.3s;
	}

	.head-menu {
		position: absolute;
		right: 0.2rem;
		top: 1.18rem;

		li:active {
			background-color: #fff;
		}

		ul {
			background-color: #fff;
			padding: 0 0.1rem;
			border-radius: 5px;

			li {
				padding: 0.2rem 0.2rem;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				font-size: 0.32rem;

				img {
					width: 0.4rem;
					height: 0.4rem;
					margin-right: 0.2rem;
				}
			}
		}
	}

	.head-menu::before {
		display: block;
		content: "";
		width: 0px;
		height: 0px;
		border-width: 0.13rem;
		border-bottom-width: 0.2rem;
		border-style: solid;
		margin-left: 70%;
		border-color: transparent transparent #fff transparent;
	}

	.boderBottom {
		position: relative;
		color: #000;
	}

	.boderBottom:after {
		content: " ";
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 1px;
		color: #aaa;
		-webkit-transform-origin: 0 100%;
		transform-origin: 0 100%;
		-webkit-transform: scaleY(0.5);
		transform: scaleY(0.5);
	}

	.g-content {
		flex: 1;
		/* display: flex; */
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		overflow-y: scroll;
		background: #fff;
	}

	.swiper-container {
		margin: 0 auto;
		width: 100%;
		transition: all 0.3s;
	}

	.scroller-box {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		width: 100%;
		overflow: auto;
	}

	.swiper-slide {
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		width: 100%;
		height: 100%;
		position: relative;
		-webkit-transition-property: -webkit-transform;
		transition-property: -webkit-transform;
		-o-transition-property: transform;
		transition-property: transform;
		transition-property: transform, -webkit-transform;
		transition-property: transform, -webkit-transform;
	}

	.slide-text {
		height: auto;
	}

	.swiper-slide-active {
		pointer-events: auto;
	}

	.issuecontant {
		top: 4.78rem;
		position: absolute;
		width: 100%;
	}

	.angleRotestow {
		transform: rotate(180deg);
		transition: transform 0.3s, -webkit-transform 0.3s;
	}

	.icon-arrow-bottoms {
		transform: rotate(0);
		transition: transform 0.3s;
	}

	.bet-panel {
		// height: 8rem;
		/* display: flex; */
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		height: 100%;
		margin-bottom: 80px;
	}

	.panel-head {
		background-color: #f6f7f9;
	}

	.category {
		display: flex;
		-ms-flex-flow: wrap;
		flex-flow: wrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.category-item {
		width: 100%;
		text-align: left;
		height: 10px;
		font-size: 0.32rem;
		color: #fff;
		font-family: 'nstb';
	}

	.PayActive {
		color: #fff;
		font-weight: bold;
	}

	.panel-body {
		flex: 1;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		overflow-y: auto;
	}

	.youxititle {
		color: #000;
		font-family: 'nstb';
		width: 100%;
		text-align: left;
		padding: .4rem 0 .2rem .3rem;
		box-sizing: border-box;
		border-bottom: .02rem solid #ececec;
	}

	.oddsExplain {
		display: flex;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: .2rem .3rem 0.3rem 0.3rem;
		overflow-x: hidden;
		font-size: .28rem;
		font-family: 'nstb';

		.tips {
			color: #aaa;
			font-family: 'nstb';
			margin-left: .1rem;
		}
	}

	.odds {
		margin-left: auto;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		min-width: 1.6rem;
		font-family: 'nstb';

		span {
			color: #aaa;
		}
	}

	.panel-body {
		flex: 1;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		overflow-y: auto;
	}

	.swiperKey {
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		list-style: none;
		padding: 0;
		z-index: 1;
		width: 100%;
	}

	.select-list {}

	.xiangmurongqi {
		padding: 0 .3rem;
		margin-top: 0;
	}

	.item {
		width: calc(25% - 3px);
		float: left;
		text-align: center;
		margin-bottom: 4px;
		margin-right: 4px;
		background-color: #f6f7f9;
		border-radius: 5px;
	}

	.item:nth-child(4n+4) {
		margin-right: 0;
	}

	.itema {
		width: calc(25% - 2px);
		float: left;
		text-align: center;
		margin-bottom: 2px;
		margin-right: 2px;
		background-color: #30385d;
	}

	.square {
		margin: 0 auto;
		border-radius: 0;
		width: 100%;
		height: 100%;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding: 0.4rem 0;

		.text {
			font-size: 0.28rem;
			line-height: .32rem;
			color: #aaa;
			margin-bottom: .12rem;
		}

		.oddsPay {
			color: #000000;
			font-size: 0.32rem;
			line-height: .32rem;
			font-family: 'nstb';
		}
	}

	.item.active .square {
		color: #fff;
		background-color: #E31837;
		border-radius: 5px;

		.text {
			font-size: 0.28rem;
			line-height: .32rem;
			color: #fff;
			margin-bottom: 0.12rem;
		}

		.oddsPay {
			color: #fff;
			font-size: 0.32rem;
			line-height: .32rem;
		}
	}

	.itema.activea .square {
		background-color: #ff7d20;

		.text {
			font-size: 0.32rem;
			color: #fff;
			margin-bottom: 0.04rem;
		}

		.oddsPay {
			color: #fff;
			font-size: 0.25rem;
		}
	}

	.panel-foot {
		z-index: 99999999;
		position: fixed;
		bottom: 0px;
		width: 100%;
		padding: .4rem 0;
		background-color: #fff;
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.leftCount {
		flex: 1;
		position: relative;
	}

	.rightBetButton {
		div {
			height: 100%;
			background-color: #666;
			padding: 0 0.1rem;
			min-width: 1.6rem;
			text-align: center;
		}
	}

	.icon-cart {
		width: 1.5rem;
		height: 1.5rem;
		position: absolute;
		left: 0.2rem;
		bottom: 0.2rem;
	}

	.bet-status {
		text-align: center;
		font-size: 0.36rem;
		color: #000;
		font-family: 'nstb';
		img{
			width: 14px;
			margin-right: 4px;
		}
	}

	.bet-sub-panel {
		padding: 0.6rem 0.3rem;

		ul {
			display: flex;
			align-items: center;
			justify-content: space-between;

			li {
				position: relative;
				color: #000;
				background: #f6f7f9;
				padding: 5px 5px;
				border-radius: 5px;
				font-size: .32rem;
				font-family: 'nstb';
			}

			li span {
				font-size: 0.18rem;
				line-height: .2rem;
				color: #aaa;
			}
		}

		ul li img {
			width: 1.1rem;
			height: 1.1rem;
			border-radius: 50%;
			-o-object-fit: cover;
			object-fit: cover;
		}
	}

	.bottom-form {
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 0.32rem;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		height: 1rem;
		line-height: 1rem;
		width: 100%;
		margin: 0.4rem auto;

		input {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
			height: 100%;
			padding: 0.3rem;
			border-radius: 5px;
			border: none;
			background: #f6f7f9;
			color: #000;
			font-family: 'nstb';
			font-size: .32rem;
		}

		.ripple.bet-btn {
			background-color: #E31837;
			width: 1.8rem;
			color: #fff;
			font-family: 'nstb';
		}

		.ripple {
			margin-left: 0.12rem;
			text-align: center;
			border-radius: 0.1rem;
			height: 100%;
		}
	}

	.betactive {
		top: -5px;
	}

	.c_a0a0a0 {
		color: #fff;
		font-size: 0.32rem;
	}

	.msg-type_1 {
		div {
			background-color: #1a243f;
			width: 50%;
			margin: 0.2rem auto;
			padding: 0.1rem;
			text-align: center;
			border-radius: 3px;
			min-height: 0.8rem;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
		}
	}

	.bet {
		width: 7rem;
		background-color: #374c7c;
		border-radius: 0.2rem;
		overflow: hidden;
		top: 35%;
		position: absolute;
		left: 15%;
	}

	.bet-title {
		background: #374c7c;
		text-align: center;
		color: #fff;
		font-size: 0.35rem;
		line-height: 0.6rem;
	}

	.bet-content {
		padding: 0 0.2rem;
	}

	.bet-content div:nth-child(-n + 2) {
		padding: 0.16rem;
		border-bottom: 1px solid #5078b2;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.bet-content div:nth-child(-n + 2) {
		padding: 0.16rem;
		border-bottom: 1px solid #5078b2;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.bet-content div:nth-child(3) {
		padding: 0.16rem 0;
		border-bottom: 1px solid #5078b2;
	}

	.bet-content div:nth-child(3) span {
		display: inline-block;
		width: 50%;
		text-align: center;
	}

	.bet-content ul li {
		padding: 0.1rem 0;
	}

	.bet-footer {
		padding: 0.2rem;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.bet-content ul li span {
		display: inline-block;
		width: 50%;
		text-align: center;
	}

	.btn-confirm {
		margin-right: 0.3rem;
		background-color: #7d58c6;
		border: 1px solid #7d58c6;
		width: 100%;
	}

	.btn-canle {
		background-color: rgb(191, 191, 191);
		border: 1px solid rgb(191, 191, 191);
		width: 100%;
	}

	.follow-btn {
		background-color: #7d58c6;
		width: 1.2rem;
		padding: 0.1rem 0.1rem;
		border-radius: 0.3rem;
		flex: none;
		width: 15%;
	}

	.stopBet {
		height: 0.48rem;
		font-size: 0.32rem;
		color: #fff;
		font-family: 'nstb';
	}

	.Xwarp {
		background: url(../assets/image/xi.png);
		width: 100%;
		height: 2.92rem;
		position: absolute;
		top: 6rem;
		z-index: 999;
		transform: translate3d(0, 0px, 0px);
		transition-duration: 2000ms;
		animation: righttoleftpiaopping 5s linear both;
		background-size: 100% 100%;

		div {
			margin-top: 1.6rem;
			font-size: 0.35rem;
			margin-left: 2.35rem;
			width: 7.5rem;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	@keyframes righttoleftpiaopping {

		/*设置内容由显示变为隐藏*/
		0% {
			transform: translate3d(-300px, 0px, 0px);
			opacity: 1;
		}

		50% {
			transform: translate3d(0, 0px, 0px);
			opacity: 1;
		}

		50% {
			transform: translate3d(200px, 0px, 0px);
			opacity: 1;
		}

		100% {
			transform: translate3d(500px, 0px, 0px);
			opacity: 0;
		}
	}
	
	
	.jpm_logo_middle {
		width: calc(100% - 0.6rem);
		padding: 1rem 1.6rem 1rem 0.6rem;
		text-align: left;
		color: rgba(0, 0, 0, 0.3);
		background: #f4f0ed;
		margin: 0 auto;
		border-radius: 5px;
	
		p:nth-child(1) {
			color: #000;
			font-family: 'nstb';
			font-size: 0.65rem;
			line-height: 0.75rem;
			margin-bottom: 5px;
		}
	
		p:nth-child(2) {
			font-size: 0.26rem;
			line-height: 0.44rem;
		}
	
	}
</style>