<template>
	<div class="about">
		<div class="settingwarp">
			<header>
				<div class="g-header">
					<div class="left" @click="changego">
						<van-icon name="arrow-left" color="#aaa" size="18px" />
					</div>
					<div class="middle fs-18">{{$t('kefu')}}</div>
					<div class="right">
						<div class="bg-icon bg-icon-setting"></div>
					</div>
				</div>
				<div class="blockHeight"></div>
				<div class="g-content">
					<div class="logo-box">
						<img src="@/assets/image/logo.svg" class="logo" alt="" />
						<!-- <p class="version">{{$t('dangqianbanben')}} JPM 23.0502.03</p> -->
					</div>
					<!-- {{$t('aboutaa')}} -->
					<div @click="See('https://lin.ee/j4mR5eq')" class="lianjie">{{$t('kefu')}}<img src="@/assets/image/linea.png" alt="" /></div>
					<!-- <div @click="See('https://lin.ee/0rf1iZA')" class="lianjie1">{{$t('kfb')}}<img src="@/assets/image/linea.png" alt="" /></div> -->
					<p class="copyright">{{$t('banquansuoyou')}} © UBS</p>
				</div>
			</header>
		</div>
	</div>
</template>
<script>
	export default {
		name: "About",
		components: {},
		data() {
			return {};
		},
		methods: {
			changego() {
				this.$router.go(-1);
			},
			See(e) {
				window.location.href = e
			},
		},
		mounted() {},
	};
</script>
<style lang="less" scoped>
	.about{

	}
	.g-content {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		overflow: scroll;
		text-align: center;
		color: #a0a0a0;
		word-wrap:break-word;
		width: 100%;
		padding: 0 0.4rem;
		margin: 0 auto;
		font-size: 0.32rem;
		line-height: 0.45rem;
		background-color: #fff;
	}

	.logo-box {
		width: 100%;
		margin: 2rem auto .5rem auto;
	}

	.logo {
		width: 140px;
	}

	.version {
		margin-top: 0.4rem;
		font-size: 0.32rem;
	}

	.copyright {
		position: absolute;
		width: 100%;
		bottom: 0.2rem;
		font-size: 0.25rem;
		color: #ccc;
		text-align: center;
		margin-left: -0.3rem;
	}
	
	.lianjie{
		background-color: #00c47d;
		width: 100%;
		margin: 1rem auto 0 auto;
		color: #fff;
		padding: 0.3rem .3rem;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		img{width: 20px;}
	}
	.lianjie1{
		background-color: #E31837;
		width: 100%;
		margin: 0.2rem auto 0 auto;
		color: #fff;
		padding: 0.25rem .3rem;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		img{width: 20px;}
	}
</style>